import React from 'react';

import { Employee } from '../api/AppData';
import QRRender from '../qrcode/QRRender';

interface EmployeeProps {
  employee: Employee;
}

export default class EmployeeCard extends React.Component<EmployeeProps> {

  render() {
    //const colors = [ "#c6e1d7", "#c4d9e2", "#eac7cd"];
    const employee = this.props.employee;
    //const id = employee.id
    // const index: number = +id;
    // const color = colors[index % colors.length];
    const color = "purple";
    const imageSrc = "/images/kriya.png"//(employee.profile) ? employee.profile : "/images/user.png";
    const base: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    }
    const style: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      width: "100%"
    }
    const title: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      fontWeight: 600
    }
    //const bgColor = "linear-gradient(to bottom, " + color + " 49.99%, " + color + " 50%, white 50.01%, white 50%)"
    const baseWithBG: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      // background: bgColor
    }
    return (
      <div style={{ ...base, borderRadius: "10px", backgroundColor: "white", marginBottom: "5px", marginTop: "5px" }} className="purble-border">
        <div style={{ ...base, backgroundColor: color, borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
          <div style={{ height: "75px" }} />
        </div>
        <div style={base}>
          <div style={baseWithBG}>
            <div><img src={imageSrc} alt="profile" className="profile-image" /></div>
          </div>
        </div>
        <div style={base}>
          <div style={{ ...title, fontSize: "1.1rem" }}>
            <div className='text-primary'>{employee.name}</div>
          </div>
        </div>
        <div style={base}>
          <div style={title}>
            <div className='text-primary'>{employee.designation}</div>
          </div>
        </div>
        <div style={{ ...base, padding: "10px" }} >
          <div style={{ ...base, width: "80%", padding: "10px" }} className="purble-border">
            <div style={style}>
              <div>Employee ID : <span className="title">{employee.id}</span></div>
            </div>
            <div style={style}>
              <div>Date Of Birth : <span className="title">{employee.dateOfBirth}</span></div>
            </div>
            <div style={style}>
              <div>Blood Group : <span className="title">{employee.bloodGroup}</span></div>
            </div>
            <div style={style}>
              <div>Phone : <span className="title">{employee.phone}</span></div>
            </div>
            <div style={style}>
              <div>Address : <span className="title">{employee.address}</span></div>
            </div>
          </div>
          <div style={{ ...style, alignItems: "center" }}>
            <div style={{ height: "10px" }} ></div>
            <div><QRRender employee={employee} /></div>
          </div>
        </div>
      </div>
    );
  }
}