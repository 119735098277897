import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { Employee } from '../api/AppData';
import EmployeeCard from '../employee/EmployeeProfile';

interface ProfileProps extends RouteComponentProps {
  id?: string;
}

export interface ProfileState {
  data?: Employee;
}

export default class Profile extends React.Component<ProfileProps, ProfileState> {

  constructor(props: ProfileProps) {
    super(props);
    this.state = {
      data: undefined
    }
  }

  componentDidMount() {
    if (this.props.id) {
      window.data.getEmployee(this.props.id).then((item) => {
        this.setState({ data: item });
      }).catch((error) => {
        window.showAlert(error.message);
      });
    }
  }

  render() {
    const value = this.state.data;
    const base: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      overflowX: 'hidden'
    }
    let ConditionRender = (value) ? <EmployeeCard employee={value} /> : <div></div>
    return (
      <div style={base}>
        <ul className="list-style p-0 m-0" style={{ width: "90%" }}>
          <div>
            {ConditionRender}
          </div>
        </ul>
        <div style={base} className="status">
          <a href="https://www.kriyamed.com">kriyamed.com</a>
        </div>
      </div>
    );
  }
}