import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';

import { Employee } from '../api/AppData';
import EmployeeCard from '../employee/EmployeeProfile';


interface HomeProps extends RouteComponentProps {

}

export interface HomeState {
  data: Array<Employee>;
}

export default class Home extends React.Component<HomeProps, HomeState> {

  constructor(props: HomeProps) {
    super(props);
    this.state = {
      data: []
    }
    this.navigate = this.navigate.bind(this);
  }

  componentDidMount() {
    /*const dummy = new DummyData();
    window.data.createEmployees(dummy.data);*/
    window.data.getAllEmployee().then((items) => {
      this.setState({ data: items });
    }).catch((error) => {
      window.showAlert(error.message);
    });
  }

  navigate(employee: Employee) {
    navigate("/employee/" + employee.id);
  }

  render() {
    const list = this.state.data;
    const base: React.CSSProperties = {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      overflowX: 'hidden'
    }
    return (
      <div style={base}>
        <ul className="list-style p-0 m-0" style={{ width: "90%" }}>
          {
            list.map((value, index) => {
              return (
                <div onClick={(e) => this.navigate(value)} key={index}>
                  <EmployeeCard employee={value} />
                </div>
              );
            })
          }
        </ul>
      </div>
    );
  }
}