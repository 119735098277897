// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, getDoc, doc, Firestore, setDoc } from "firebase/firestore";

declare global {
  interface Window {
    data: AppData;
    showAlert: ((message: string, title?: string, confirmation?: boolean, callback?: ((confirmed: boolean) => void)) => void);
  }
}

export interface Employee {
  id: string;
  name: string;
  designation: string;
  bloodGroup: string;
  dateOfBirth: string;
  phone: string;
  address: string;
}

class AppData {

  database: Firestore

  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyA4jgzEL6odDIy8244fdIKcuA8MzoL3Iew",
      authDomain: "kriyaemployee.firebaseapp.com",
      projectId: "kriyaemployee",
      storageBucket: "kriyaemployee.appspot.com",
      messagingSenderId: "706585042777",
      appId: "1:706585042777:web:2afbf5ef8be16c1bd5b648",
      measurementId: "G-ZGSN455D21"
    };
    const app = initializeApp(firebaseConfig);
    this.database = getFirestore(app)
  }

  async getAllEmployee(): Promise<Array<Employee>> {
    try {
      const employeeCollection = collection(this.database, "employee");
      const querySnapshot = await getDocs(employeeCollection);
      const employees = querySnapshot.docs.map(doc => doc.data() as Employee);
      return new Promise((resolve, reject) => {
        resolve(employees);
      });
    } catch (e) {
      return new Promise((resolve, reject) => {
        reject(e);
      });
    }
  }

  async getEmployee(id: string): Promise<Employee> {
    try {
      const docRef = doc(this.database, "employee", id);
      const docSnap = await getDoc(docRef);
      const employee = docSnap.data() as Employee;
      return new Promise((resolve, reject) => {
        resolve(employee);
      });
    } catch (e) {
      return new Promise((resolve, reject) => {
        reject(e);
      });
    }
  }


  async createEmployee(employee: Employee) {
    const docRef = doc(this.database, "employee", employee.id);
    await setDoc(docRef, employee);
  }

  async createEmployees(employees: Array<Employee>) {
    for (let employee of employees) {
      this.createEmployee(employee);
    }
  }
}

export default AppData

/*export class DummyData {
  data: Array<Employee> = [
    {
      id: "20015",
      name: "Riby Thomas",
      designation: "Director-Sales",
      bloodGroup: "A+ve",
      dateOfBirth: "5-Jun-1987",
      phone: "8754405687",
      address: "Flat No 22, 107/16, 29th street, besant nagar, chennai- 600090"
    },
    {
      id: "20019",
      name: "Ganesh V",
      designation: "Assistant Manager-Finance",
      bloodGroup: "O+ve",
      dateOfBirth: "8-Aug-1987",
      phone: "9884580305",
      address: "No.39, 4th Street, Shanthi Nagar, Vyasarpadi, Chennai- 600039"
    },
    {
      id: "20022",
      name: "Nirmala Devi N",
      designation: "Supervisor-Production",
      bloodGroup: "A1+ve",
      dateOfBirth: "24-Aug-1994",
      phone: "9087784385",
      address: "No.21, Gandhi Salai, Periyar Nagar, Alwarthirunagar, Chennai - 600087"
    },
    {
      id: "20023",
      name: "Ambika M",
      designation: "Machine Operator- Production",
      bloodGroup: "O+ve",
      dateOfBirth: "14-Jun-1984",
      phone: "8608038469",
      address: "No.19, G Block, Kailasa Mudali Street, Tondirapet, Ch-600052"
    },
    {
      id: "20024",
      name: "Sheena K",
      designation: "Machine Operator- Production",
      bloodGroup: "B-ve",
      dateOfBirth: "15-Aug-1979",
      phone: "9176007344",
      address: "No.131, Palani Andavar Kovil Street, Aynavaram, Ch- 600023"
    },
    {
      id: "20030",
      name: "Rosy P",
      designation: "Quality & Product application Manager",
      bloodGroup: "A+ve",
      dateOfBirth: "25-Jul-1975",
      phone: "9710673703",
      address: "New No. 7, Old No. 4, Bishop Lane, Purasavakkam, Doveton, Chennai - 600007"
    },
    {
      id: "20032",
      name: "Balaji D",
      designation: "Assistant Manager-Molding",
      bloodGroup: "O+ve",
      dateOfBirth: "19-Apr-1983",
      phone: "8098971785",
      address: "Gb, Ragam appartments, veerapandi nagar, 4th street, choolaimedu , chennai - 600089"
    },
    {
      id: "20035",
      name: "Saravanan S",
      designation: "Senior Engineer Ğ Projects & Maintenance",
      bloodGroup: "B+ve",
      dateOfBirth: "12-Aug-1994",
      phone: "9524581586",
      address: "No.17/26, Madhavpuram west St, Alandur, Chennai -"
    },
    {
      id: "20036",
      name: "Harikumar K",
      designation: "Technician-Maintenance",
      bloodGroup: "A1+ve",
      dateOfBirth: "13-May-1978",
      phone: "9003111074",
      address: "No.28/29A, Balaji Nagar, 2nd Cross Street, Kolathur, Ch - 99"
    },
    {
      id: "20038",
      name: "Kumaran R",
      designation: "Manager-Product Development",
      bloodGroup: "A+ve",
      dateOfBirth: "16-Oct-1984",
      phone: "9740532602",
      address: "No.19/24, Krishnasamy St, Palavanthangal, Chennai- 600114"
    },
    {
      id: "20044",
      name: "Dinesh Kumar S",
      designation: "Design Engineer-Product Development",
      bloodGroup: "A2B+ve",
      dateOfBirth: "4-Apr-1999",
      phone: "8667812304",
      address: "93/32 Bharathi Nagar, 2nd Street, Villivakkam, Chennai - 600 049."
    },
    {
      id: "20045",
      name: "Anand A",
      designation: "Assistant Manager-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "17-Sep-1981",
      phone: "9597305898",
      address: "F-62, 4th Street, Chintamani, Annanagar East, Chennai "
    },
    {
      id: "20049",
      name: "Jeevesh S",
      designation: "Design Engineer-Product Development",
      bloodGroup: "B+ve",
      dateOfBirth: "23-Jun-1997",
      phone: "7502767333",
      address: "No:46, Antony Nagar, 3rd street, moogambigai nagar main road, kallikuppam, Ambattur, Chennai - 600053"
    },
    {
      id: "20051",
      name: "Naresh A",
      designation: "Engineer-Maintenance",
      bloodGroup: "B+ve",
      dateOfBirth: "1-Jan-1992",
      phone: "8610230944",
      address: "No 394 Nadu Street, Thirumalpur Village, Nemili, Ranipet - 631 051."
    },
    {
      id: "20053",
      name: "Pandidurai S",
      designation: "Executive-Quality",
      bloodGroup: "O+ve",
      dateOfBirth: "4-Mar-1992",
      phone: "9994567419",
      address: "34/41, Boombugar Street, Arumbakkam, Chennai"
    },
    {
      id: "20054",
      name: "Neelakandan C.S",
      designation: "Trainee-Warehouse",
      bloodGroup: "O+ve",
      dateOfBirth: "20-Jul-1994",
      phone: "9655062337",
      address: "F-62, 4th Street, Chintamani, Annanagar East, Chennai "
    },
    {
      id: "20056",
      name: "Rajagopal G",
      designation: "Manager-Logistics",
      bloodGroup: "B-ve",
      dateOfBirth: "30-Nov-1963",
      phone: "9840504606",
      address: "41/20, Ramamoorthy colony main road, Peravallur, TVK Nagar, Chennai - 600 082. "
    },
    {
      id: "20061",
      name: "Ganesh Paneerselvam",
      designation: "Executive-Quality",
      bloodGroup: "B+ve",
      dateOfBirth: "20-Apr-1994",
      phone: "8939569355",
      address: "1/ 230 Kaliamman kovil street, Karapakkam, Chennai - 600097"
    },
    {
      id: "20066",
      name: "S. Sakthivel",
      designation: "Process Engineer & Production",
      bloodGroup: "O+ve",
      dateOfBirth: "6/Nov/97",
      phone: "9514553314",
      address: "73, Raja Street, Isravel Nagar, Gowrimedu, Puducherry - 605006"
    },
    {
      id: "20067",
      name: "M. Suresh Kumar",
      designation: "Engineer- Injection molding",
      bloodGroup: "A1+ve",
      dateOfBirth: "30/Sep/83",
      phone: "8760058320",
      address: "No 248 Ozhalur (po) Ambethkar Nagar,Nenbakkam (vi) Chengalpattu 603111"
    },
    {
      id: "20068",
      name: "Rahmath Nisha.A",
      designation: "HR Trainee",
      bloodGroup: "O+ve",
      dateOfBirth: "13-Dec-1993",
      phone: "9786247333",
      address: "Plot No 305,MKS Villas,Aringar anna 4th street,Kajendiran road,Thiruverkadu Coperative nagar,Chennai-600077"
    },
    {
      id: "20069",
      name: "A. Arivalagan",
      designation: "HR Executive",
      bloodGroup: "B+ve",
      dateOfBirth: "9-Oct-1993",
      phone: "9962649839",
      address: "No,59,Mariamman Kovil street,86 ,Vallam,Chengalpattu-603002"
    },
    {
      id: "20071",
      name: "Rajendran",
      designation: "Head-Domestic sales",
      bloodGroup: "B+ve",
      dateOfBirth: "17 Mar 1973",
      phone: "9840346894",
      address: "Old No 6, New No.1 Bharathi nagar,Arumbakkam,chennai-600106"
    },
    {
      id: "20075",
      name: "Sangavi S",
      designation: "Material Handler-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "20 Jan 1997",
      phone: "9940950760",
      address: "No.112, Bajanai kovil Street, Pazhaya seevaram,Kanchipuram,Pin-631606"
    },
    {
      id: "20076",
      name: "A.Vasudevan",
      designation: "Operator-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "27 Jul 1997",
      phone: "8438152760",
      address: "No.159,Mettu Street,86 Vallam Chengalpattu,Pin-603002"
    },
    {
      id: "20077",
      name: "Santhosh R",
      designation: "Operator-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "18 Apr 1997",
      phone: "9092807357",
      address: "No.453/B2,K.K.nagar vallalar steert,Venbakkam Chengalapattu"
    },
    {
      id: "20078",
      name: "Uma GM",
      designation: "Executive-Quality",
      bloodGroup: "A+ve",
      dateOfBirth: "10 Jul 1996",
      phone: "9751216171",
      address: "No.83/93,Otraivadai street, Sorakkaipet,Thriuvallur Dist,Pin -631208"
    },
    {
      id: "20079",
      name: "Ramar K",
      designation: "Executive-Quality",
      bloodGroup: "B+ve",
      dateOfBirth: "12 May 2001",
      phone: "6382497319",
      address: "No.4/108A, Vadipatti Taluka,Thethoor Post Ellaiyur, Thethoor,Madurai,Pin-625503"
    },
    {
      id: "20080",
      name: "V.Prakash",
      designation: "Technician-Maintenance",
      bloodGroup: "O+ve",
      dateOfBirth: "25 Jul 1983",
      phone: "9966560286",
      address: "No.58, PTC Quteras , Dhanaselvi nagar, varadharajapuram,600048"
    },
    {
      id: "20081",
      name: "Jikin C",
      designation: "Supervisor-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "13 Apr 1998",
      phone: "7871849539",
      address: "S-3,Tamil Promoter Apartment,Nellikupam road,Guduvanchery,Pin 603202"
    },
    {
      id: "20082",
      name: "Balaji K",
      designation: "Assistant manager-SCM",
      bloodGroup: "B-ve",
      dateOfBirth: "22 Apr 1984",
      phone: "9677004669",
      address: "No.5 soth street, west tambaram,chennai 42"
    },
    {
      id: "20083",
      name: "Jane Sheeba Varghese",
      designation: "Business Associate-Sales",
      bloodGroup: "O+ve",
      dateOfBirth: "03 Jul 1998",
      phone: "9840957474",
      address: "No.2B,Harasham Apts,70/71,Logaiah colony,2 main road, Saligramam,Chennai-93"
    },
    {
      id: "20084",
      name: "Kannan K",
      designation: "Executive-Quality",
      bloodGroup: "O+ve",
      dateOfBirth: "01 Jul 1992",
      phone: "7639156015",
      address: "No.10,Primary school street, bukkathurai village (post),Chengapattu"
    },
    {
      id: "20085",
      name: "Archana",
      designation: "Executive-Quality",
      bloodGroup: "A2+ve",
      dateOfBirth: "14 Mar 1996",
      phone: "9940427706",
      address: "No.5/130,Ambathkar Street, Vanchuvanchery,padappai-601301"
    },
    {
      id: "20086",
      name: "Gokul C",
      designation: "Technician- Maintenance",
      bloodGroup: "B+ve",
      dateOfBirth: "30 Dec 1998",
      phone: "7397417488",
      address: "No.155 yadaver street vallam"
    },
    {
      id: "20087",
      name: "Velu S",
      designation: "Assistant Manager-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "04 Sep 1990",
      phone: "7887947294",
      address: "No.107/5,Durkai amman kovil street, near Light House,Maylapore,Chennai-4"
    },
    {
      id: "20088",
      name: "Thulasidoss S",
      designation: "Technician- Maintenance",
      bloodGroup: "O+ve",
      dateOfBirth: "19 May 1993",
      phone: "7708556689",
      address: "No.55E/121, G.S.T road, Thimmaraja Kulam,Chengalpattu"
    },
    {
      id: "20089",
      name: "Yagapriya",
      designation: "Accounts Executive-Finance",
      bloodGroup: "O+ve",
      dateOfBirth: "26 May 1994",
      phone: "9652134807",
      address: "No.12,Sampangi Nagar 1st Street,Tiruninravur, Tiruvallur-602024"
    },
    {
      id: "20090",
      name: "Sekar C",
      designation: "Supervisor-Maintenance",
      bloodGroup: "B+ve",
      dateOfBirth: "03 Mar 1988",
      phone: "9626879940",
      address: "No.5/46, Veethakovil street,KombankulamThoothukkudi-628704"
    },
    {
      id: "20092",
      name: "Raman M",
      designation: "Technician-Molding",
      bloodGroup: "A1+ve",
      dateOfBirth: "07 Jun 1995",
      phone: "7358317184",
      address: "No.31,Muneswaran koil Street,A.K.palayam,peramanur,Maraimalainagar,Chengalpattu, Chennai-603209"
    },
    {
      id: "20093",
      name: "Seenivasan R",
      designation: "Technician-Molding",
      bloodGroup: "O+ve",
      dateOfBirth: "01 Jan 1993",
      phone: "9087159711",
      address: "No.7/106,East car street,pullukkulam,Padappai"
    },
    {
      id: "20094",
      name: "Sasikumar P",
      designation: "Supervisor-Molding",
      bloodGroup: "O+ve",
      dateOfBirth: "02 Jun 1995",
      phone: "9843223736",
      address: "No.12,First Cross street,Thalavaram Pooradi,Sriungoli(post) Kanchipuram"
    },
    {
      id: "20095",
      name: "Ruban P",
      designation: "Technician-Molding",
      bloodGroup: "B+ve",
      dateOfBirth: "02 May 1994",
      phone: "7904141216",
      address: "No.1/82,East Street,Odaimarichan(post),Tirunelveli-627602"
    },
    {
      id: "20096",
      name: "Dinesh Raj R",
      designation: "Operator-Production",
      bloodGroup: "A+ve",
      dateOfBirth: "10 Sep 1994",
      phone: "9629821289",
      address: "No.8 p Block venkatapuram ,Little mount saidpet, Chennai 15"
    },
    {
      id: "20097",
      name: "Easwara Kumar Y",
      designation: "Supervisor-Production",
      bloodGroup: "A+ve",
      dateOfBirth: "20 Jun 1989",
      phone: "9715473242",
      address: "N0.56 10 street eswaran nagar, thirukachur,singaperumal koil 603204"
    },
    {
      id: "20098",
      name: "Srinivasan S",
      designation: "Supervisor-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "11 Apr 1986",
      phone: "9962001996",
      address: "No .plot 1 phase 6 vijayalakshmi nagar, Manapakam,chingapattu"
    },
    {
      id: "20099",
      name: "Mohanraj J",
      designation: "Microbiologist",
      bloodGroup: "B+ve",
      dateOfBirth: "23 May 1999",
      phone: "9384373772",
      address: "No.1/67,Kulakarai Street, Sendamangalam,kanchipuram,Pin-602106"
    },
    {
      id: "20100",
      name: "Jothi Prakash R",
      designation: "Microbiologist",
      bloodGroup: "O+ve",
      dateOfBirth: "10 Mar 1994",
      phone: "9677494294",
      address: "No.06,Valliammal Salai,Periyar Nagar,Alwarthirunagar,valasaravakam,Chennai-87"
    },
    {
      id: "20101",
      name: "Dinesh Kumar K",
      designation: "Quality Engineer",
      bloodGroup: "B+ve",
      dateOfBirth: "14 Apr 1998",
      phone: "7824018666",
      address: "No.10/272,Indra gandhi street,Indra nagar,Perumbakkam,Chennai-126"
    },
    {
      id: "20102",
      name: "Karthikeyan Kesavan",
      designation: "Scientist & STEM Lead",
      bloodGroup: "O+ve",
      dateOfBirth: "08 May 1991",
      phone: "9944666752",
      address: "Sriperambathur"
    },
    {
      id: "20104",
      name: "Ilayaraja V",
      designation: "Senior Engineer-Stores and purchase",
      bloodGroup: "O+ve",
      dateOfBirth: "01 Mar 1982",
      phone: "9715642488",
      address: "No.5B, Vellalar Street, Gandhi road,Velachery,Chennai-600042"
    },
    {
      id: "21016",
      name: "Paul Raj S",
      designation: "Supervisor-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "27 Jul 1996",
      phone: "7299833412",
      address: "No.4 , 2nd , Palla Street, Vyasarpadi, chennai - 600039"
    },
    {
      id: "21020",
      name: "Kishore Kumar A",
      designation: "Machine Operator-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "08 Feb 1997",
      phone: "8754948179",
      address: "82, 4th block, Sathyamurthi nagar, Vyasarpadi, chennai - 600039"
    },
    {
      id: "21026",
      name: "Manikandan C",
      designation: "Machine Operator-Production",
      bloodGroup: "A+ve",
      dateOfBirth: "09 Oct 1998",
      phone: "6382610033",
      address: "18/90 B, Gujji Main Street,VOC nagar, Anna Nagar East, Chennai - 600102"
    },
    {
      id: "21027",
      name: "Joan B",
      designation: "Machine Operator-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "13 May 1996",
      phone: "9551294037",
      address: "No.10, 148 jothiammal nagar, East Club Road, Chennai - 600030"
    },
    {
      id: "21028",
      name: "Vinetha D",
      designation: "Machine Operator-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "13 Apr 1999",
      phone: "9940386959",
      address: "18/90 A, Gujji Main Street,VOC nagar, Anna Nagar East, Chennai - 6000102"
    },
    {
      id: "21044",
      name: "Sankar S",
      designation: "Machine Operator-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "12 Dec 2000",
      phone: "9940359892",
      address: "No 1/A, Raghavendra Kovil Street, Indian Bank Colony, 5th Main Road, Ambattur, Chennai - 600053"
    },
    {
      id: "21048",
      name: "Krishna G",
      designation: "Machine Operator-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "02 Oct 1998",
      phone: "8825469269",
      address: "No.50/A Thrimualai Nagar, 1st main road, Kolathur,Chennai-600039"
    },
    {
      id: "21054",
      name: "Amuthavalli",
      designation: "Machine Operator-Production",
      bloodGroup: "A+ve",
      dateOfBirth: "05 Apr 1984",
      phone: "9789819068",
      address: "287, MTH Road, Annai sathya nagar, Villivakkam, Chennai - 600 049."
    },
    {
      id: "21059",
      name: "Suresh C",
      designation: "Machine Operator-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "28 Dec 1998",
      phone: "8072101848",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21060",
      name: "Naveen K",
      designation: "Machine Operator-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "28 Jun 2000",
      phone: "7094668861",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21061",
      name: "Saravanan M",
      designation: "Machine Operator-Production",
      bloodGroup: "A1+ve",
      dateOfBirth: "28 May 2001",
      phone: "9751943911",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21063",
      name: "Murugan V",
      designation: "Machine Operator-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "25 Dec 2002",
      phone: "6383503049",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21065",
      name: "Arun Kumar C",
      designation: "Machine Operator-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "19 Nov 2002",
      phone: "7094953183",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21066",
      name: "Manickavasagam T",
      designation: "Machine Operator-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "29 Oct 1989",
      phone: "9025074875",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21068",
      name: "Magesh Kumar R",
      designation: "Machine Operator-Production",
      bloodGroup: "A1+ve",
      dateOfBirth: "06 Jun 1999",
      phone: "8675016719",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21071",
      name: "Sathish S",
      designation: "Machine Operator-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "16 May 2000",
      phone: "9361637986",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21072",
      name: "Aruldoss R",
      designation: "Machine Operator-Production",
      bloodGroup: "O+ve",
      dateOfBirth: "10 Apr 1999",
      phone: "8072437118",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21073",
      name: "Ramajayam S",
      designation: "Machine Operator-Production",
      bloodGroup: "A+ve",
      dateOfBirth: "30 Aug 2000",
      phone: "9025392506",
      address: "Naanna Elite Me's Hostel, 7-2-26, J J Nagar, Mogappair East, Chennai - 600 050."
    },
    {
      id: "21077",
      name: "Vijaivasudev MJ",
      designation: "GET-Product Development",
      bloodGroup: "B+ve",
      dateOfBirth: "19-Oct-1998",
      phone: "9655733436",
      address: "1/14 - 4124. Kailash nagar, near EB office, tharamegalam, omalur main road, salem - 636802"
    },
    {
      id: "10014",
      name: "Silja A.K",
      designation: "Business Support Executive-Sales",
      bloodGroup: "B+ve",
      dateOfBirth: "13 Aug 1978",
      phone: "7299692036",
      address: "9/3 D,Iyyapan cross street Ramapuram,Ambattur,Chennai-53"
    },
    {
      id: "10039",
      name: "Sanjay Kumar Paul",
      designation: "Regional Sales Manager",
      bloodGroup: "B+ve",
      dateOfBirth: "28 May 1978",
      phone: "9553292070",
      address: "Supriya Residency, Flat No Ğ 301, Near Friends Colony, Near Pleasant Park,Attapur, Hyderabad, Telangana - 500 048."
    },
    {
      id: "10049",
      name: "Joseph Thomson CT",
      designation: "Office Assistant",
      bloodGroup: "B+ve",
      dateOfBirth: "27 Apr 1981",
      phone: "9840032410",
      address: "20/20,2 street,Chanthira Sekar Nagar,Kodugaiur,Ch-118"
    },
    {
      id: "10052",
      name: "Madhu M",
      designation: "Territory Sales Manager",
      bloodGroup: "O+ve",
      dateOfBirth: "10 Oct 1994",
      phone: "7406501708",
      address: "No. 1579,1st cross, 10th ward, Doddaballapur,Bangalore Rural -  561203"
    },
    {
      id: "10056",
      name: "Suthakar C",
      designation: "Territory Sales Manager",
      bloodGroup: "AB+ve",
      dateOfBirth: "16 Mar 1980",
      phone: "9629327254",
      address: "391/7, Narasimman Kovil Street, Allikuttai Post, Salem - 636003"
    },
    {
      id: "20105",
      name: "Geetha.S",
      designation: "Supervisor-Production",
      bloodGroup: "B+ve",
      dateOfBirth: "12-May-87",
      phone: "7338951693",
      address: "159/180A,Buvaneswari Nagar,1st Amman Kovil Street,Metukuppam,Chennai-600107"
    }
  ]
}*/