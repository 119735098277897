import React from 'react';
import { Router } from '@reach/router';
import './App.css';
import Home from './component/screen/Home';
import Profile from './component/screen/Profile';
import AlertUI, { AlertUIProps } from './component/alert/AlertUI';
import AppData from './component/api/AppData';

interface ApplicationProps {

}

interface ApplicationState {
  show: boolean;
  message: string;
  title: string;
  confirmation: boolean;
  callback: ((confirmed: boolean) => void);
  alertCallback?: ((confirmed: boolean) => void);
}

export default class Application extends React.Component<ApplicationProps, ApplicationState> {

  constructor(props: ApplicationProps) {
    super(props);
    this.showAlert = this.showAlert.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    window.showAlert = this.showAlert;
    window.data = new AppData();
    this.state = {
      show: false,
      message: "",
      title: "Kriyamed",
      confirmation: false,
      callback: this.closeAlert
    };
  }

  showAlert(message: string, title?: string, confirmation?: boolean, callback?: ((confirmed: boolean) => void)) {
    this.setState({
      show: true,
      message: message || "",
      title: title || "Kriyamed",
      confirmation: confirmation || false,
      alertCallback: callback
    });
  }

  closeAlert(confirmed: boolean) {
    //this.state.alertCallback?.(confirmed);
    this.setState({
      show: false,
      message: "",
      title: "Kriyamed",
      confirmation: false,
      alertCallback: undefined
    });
  }


  render() {
    const alertProps = this.state as AlertUIProps;
    return (
      <div>
        <Router>
          <Profile path="/employee/:id" />
          <Home path="/*" />
        </Router>
        <AlertUI {...alertProps} />
      </div>
    );
  }

}
