import * as React from "react";
import QRCode from "qrcode.react";
import { Employee } from '../api/AppData';

interface QRRenderProps {
  employee: Employee;
}

export default class QRRender extends React.Component<QRRenderProps> {

  private qrRef: React.RefObject<HTMLDivElement>;

  constructor(props: QRRenderProps) {
    super(props);
    this.downloadQRCode = this.downloadQRCode.bind(this);
    this.qrRef = React.createRef();
  }

  downloadQRCode() {
    // @ts-ignore
    let canvas = this.qrRef.current.querySelector("canvas");
    if (canvas) {
      let image = canvas.toDataURL("image/png");
      let anchor = document.createElement("a");
      anchor.href = image;
      anchor.download = this.props.employee.id + ".png";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  };

  render() {
    const employee = this.props.employee;
    return (
      <div className="qr-container" ref={this.qrRef} onClick={(e) => { e.preventDefault(); this.downloadQRCode(); }}>
        <QRCode
          id="qrCodeElToRender"
          size={150}
          value={"https://employee.kriyamed.com/employee/" + employee.id}
          bgColor="white"
          fgColor="black"
          level="H"

        />
      </div>
    );
  }
}