import React from 'react';
import { Modal, Button } from 'react-bootstrap-v5';

export interface AlertUIProps {
  show: boolean;
  message: string;
  title: string;
  confirmation: boolean;
  callback: ((confirmed: boolean) => void);
}

export default class AlertUI extends React.Component<AlertUIProps> {

  render() {
    const props = this.props;
    const buttonName = (props.confirmation) ? "Confirm" : "Okay"
    return (
      <Modal show={props.show} variant="info" onHide={() => props.callback(false)} centered={true} >
        <Modal.Header className="border-0 p-0 pt-3 ps-3">
          <Modal.Title className="text-primary">{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pe-3">
          {props.message}
        </Modal.Body>
        <Modal.Footer className="border-0 p-0 pb-3 pe-3">
          <Button onClick={() => props.callback(false)} variant="danger" hidden={!props.confirmation} className="pe-3">
            Cancel
          </Button>
          <Button onClick={() => props.callback(true)} variant="primary">
            {buttonName}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

}